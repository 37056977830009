import {
    ref,
    watch,
    computed
} from '@vue/composition-api'
import store from '@/store'
import {
    title
} from '@core/utils/filter'
import axios from '@axios';

// Notification
import {
    useToast
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useList() {
    const modulename = "Income";
    const modulenamesub = "expense";
    const date = new Date();
    var fromdate = new Date(date.getTime());
    fromdate.setDate(date.getDate() - 7);
    var todate = new Date(date.getTime());
    todate.setDate(date.getDate() + 3);

    var fromdatex = ref(fromdate.getFullYear() + "-" + (fromdate.getMonth() + 1) + "-" + fromdate.getDate())
    var todatex = ref(todate.getFullYear() + "-" + (todate.getMonth() + 1) + "-" + todate.getDate())
    // const todatex=ref(date.getDate()+"-"+(date.getMonth()+1)+"-"+date.getFullYear())
    const toast = useToast()

    const xname = ref('Category');
    const yname = ref('Amount');
    const xvalue = ref([]);
    const yvalue = ref([]);
    const download = ref(0);
    const xoptions = ['Category', 'Paid By'];
    const selectedoptions = ref([]);

    const refDataListTable = ref(null)

    // Table Handlers
    const selectopt = [
        'Date',
        'Amount',
        'Paid By',
        'Category',
        'Remitter',
    ];
    // if(selectedoptions.value.length<=0){
    //   selectedoptions.value.push(selectopt[0]);
    // }
    var tableColumns = [
        {
            key: 'holdername',
            label: 'Name',
            sortable: false
        },
        {
            key: 'wallet',
            label: 'Wallet',
            sortable: false
        },
        {
            key: 'limit',
            label: 'Limit (Amount)',
            sortable: false
        },
        {
            key: 'balance',
            label: 'Outstanding Balance',
            sortable: false
        },
    ]
    const category = ref(0)
    const perPage = ref(10)
    const totalData = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('created_at')
    const isSortDirDesc = ref(true)

    const datax = ref();
    var countx = 0;
    const dataMeta = computed(() => {
        const localItemsCount = refDataListTable.value ? refDataListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalData.value,
        }
    })

    const refetchData = () => {
        // if (refDataListTable.value) {
        refDataListTable.value.refresh()
        // }
    }
    const calcgraph = () => {
        xvalue.value = []
        yvalue.value = []
        var xtemp = ''
        var ytemp = 'amount'
        var ctx = datax.value
        for (var i = 0; i < ctx.length; i++) {
            if (xname.value == 'Category') {
                xtemp = ctx[i].data.category
            } else if (xname.value == "Paid By") {
                xtemp = ctx[i].data.paidby
            }

            var amount = parseFloat(ctx[i].data.amount)
            if (!isNaN(amount)) {
                var temppos = xvalue.value.indexOf(xtemp)
                if (temppos == -1) {
                    xvalue.value.push(xtemp)
                    yvalue.value.push(amount)
                } else {
                    yvalue.value[temppos] += amount
                }
            }
        }

    }

    const changetype = (val) => {
        category.value = val
    }
    watch([todatex, fromdatex, currentPage, perPage, searchQuery, category], () => {
        refetchData()
    })




    const fetchData = (ctx, callback) => {
        store
            .dispatch('reports-liabilities-paylater/fetchDatas', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value ? sortBy.value : 'created_at',
                sortDesc: isSortDirDesc.value ? 'desc' : 'asc',
                todatex: todatex.value,
                fromdatex: fromdatex.value,
                category: category.value
            })
            .then(response => {
                datax.value = response.data.data
                const datas = response.data.data
                callback(datas)
            })
            .catch((error) => {
                console.log(error)
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        text: "Couldnt find " +  + " details",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    const downloadreport = (ctx, callback) => {
        axios({
                url: 'https://userfrp.smartfrp.com/api/reports/liabilities_paylater/download',
                params: {
                    q: searchQuery.value,
                    perPage: perPage.value,
                    page: currentPage.value,
                    sortBy: sortBy.value ? sortBy.value : 'created_at',
                    sortDesc: isSortDirDesc.value ? 'desc' : 'asc',
                    todatex: todatex.value,
                    fromdatex: fromdatex.value,
                    download: download.value
                },
                method: 'POST',
                responseType: 'blob',
            })
            .then(response => {
                let blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Report - Liabilities ( Pay Later ) ' + (category.value == 0 ? '' : '') + '.xlsx'
                link.click()
            })
            .catch((res) => {
                console.group(res)
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        text: "Couldnt find " +  + " details",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            }).
        then(() => {
            download.value = 0
        })
    }
    watch([download], () => {
        if (download.value == 1) {
            downloadreport()
        }
    })

    // *=======================---*
    // *--------- UI ---------------------------------------*
    // *=======================---*

    const resolveDataRoleVariant = is_active => {
        if (is_active) return 'primary'
        else return 'danger'
    }


    watch([xname, yname], () => {

        calcgraph()
    })


    return {
        selectopt,
        fetchData,
        tableColumns,
        perPage,
        currentPage,
        totalData,
        selectedoptions,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDataListTable,
        fromdatex,
        todatex,
        resolveDataRoleVariant,
        refetchData,
        xoptions,
        xvalue,
        yvalue,
        xname,
        download,
        yname,
        category,
        changetype
    }
}
